<template>
	<section>
		<div class="d-flex align-center white-bg pt-2">
	        <b-col lg="6" style="padding-left: 33px ">
	            <h2 class="mb-2"><strong>Basic Info</strong></h2>
	        </b-col>
	        <b-col lg-6 class="pr-2">	        					            	
	        	<div class="text-right mb-2">
	        		                
	               <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAction" v-if="!isEditing" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>
	                <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAction" v-if="!isEditing">
	                    Save
	                </b-button>	                
	                <template v-else-if="isEditing">
		                <template v-if="detail.permission_edit">
		        			<b-button type="button" variant="primary" class="btn-df size-18 black"  @click="editAction">
			                    Edit
			                </b-button>
		        		</template>
		        		<template v-else>
		        			<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
			                    Edit
			                </b-button>
		        		</template>	               
		        	</template>
		        	
	            </div>						                
	        </b-col>
	    </div>				                
	    <div style="border-top: 1px solid #000000B2;" class="pb-2 white-bg"></div>
		<validation-observer
	        ref="form_rel"
	        #default="{invalid}" >
	        <b-form @submit.prevent class="profile-frm mb-20" style="border-radius: 5px">
	            <div class="frm-basic size-16 black-child d-block">
	              	<b-row style="width: 100%; margin: 0;">   
	                  	<b-col lg="6" class="px-0"> 
	                      	<b-form-group label="Project Name" label-cols-lg="4" class="star-required input-group-label">  
	                      		<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Name"
	                              	rules="required"                                  
	                            >                          
	                                <b-form-input
	                                	v-model="detail.name"
	                                    placeholder="Project Name"
	                                    :disabled="isDisabled"
	                                    :formatter="length100"
	                                ></b-form-input>
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                      	</b-form-group>
	                  	</b-col>
	                  	<b-col lg="6" class="px-0">
	                      	<b-form-group label="Project Code" label-cols-lg="4" class="star-required">
	                      		<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Code"
	                              	rules="required"                          
	                            >      
	                                <v-select
	                                	v-model="detail.project_code"
	                                    label="code"
	                                    :options="projectCode"
	                                    placeholder="Project Code"
	                                    :disabled="isDisabled"
                              			:id="disableField"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                     	</b-form-group>
	                  	</b-col>  
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Country" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="selectedCountry"
	                              	rules="required"
	                            >     
	                            	<template>
	                            		<v-select
		                                	v-model="selectedCountry"
		                                    label="name"
		                                    :options="selectCountry"
		                                    placeholder="Select Country"
		                                    :disabled="isDisabled"
                              				:id="disableField"
		                                />
	                            	</template>	                                
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>         
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Province/State/City" label-cols-lg="4" class="star-required input-group-label">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Province / State / City"
	                              	rules="required"                                  
	                            >      
	                            	<b-form-input
	                            		v-model="detail.province_state_city"
	                                    placeholder="City"
	                                    :disabled="isDisabled"
	                                ></b-form-input>
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>         
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Project Owner" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Owner"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedOwner"
	                                    label="full_name"
	                                    :options="selectOwner"
	                                    placeholder="Select Owner"
	                                    :disabled="isDisabled"
                              			:id="disableField"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>         
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Project Status" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Status"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedStatus"
	                                    label="title"
	                                    :options="selectStatus"
	                                    placeholder="Select Status"
	                                    :disabled="isDisabled"
                              			:id="disableField"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col> 
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Client" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Client"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedClient"
	                                    label="name"
	                                    :options="selectClient"
	                                    placeholder="Select Organisation"
	                                    :disabled="isDisabled"
                              			:id="disableField"
	                                />	
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>               
	                        </b-form-group>
	                  	</b-col>      
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Partner" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Partner"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedPartner"
	                                    label="name"
	                                    :options="selectPartner"
	                                    placeholder="Select Organisation"
	                                    :disabled="isDisabled"
                              			:id="disableField"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>      
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Project Value" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Value"
	                              	rules="required"                                  
	                            >     
	                                <itees-form-input-currency
					                  	v-model="detail.amount"
					                  	type="number"
					                  	placeholder="8,888.00"
					                  	:disabled="isDisabled"
					                ></itees-form-input-currency>
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>   
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Currency" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Currency"
	                              	rules="required"                                  
	                            >      	
	                                <v-select
	                                	v-model="detail.currency_name"
	                                    label="value"
	                                    :options="selectCurrency"
	                                    placeholder="SGD"
	                                    :disabled="isDisabled"
                              			:id="disableField"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="12" class="px-0">
	                  		<div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
	                        <b-form-group label="Project Description" label-cols-lg="2">
	                        	<b-form-textarea
	                        		v-model="detail.description"
	                                placeholder="Brief Description"
	                                rows="3"
	                                :disabled="isDisabled"
	                                :formatter="length1000"
	                            ></b-form-textarea>
	                        </b-form-group>
	                        <div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
	                  	</b-col>  
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Start Date" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Start date"
	                              	rules="required"                                  
	                            >      
	                                <flat-pickr
	                                	v-model="detail.commencement_date"
	                                  	class="form-control"           
	                                  	:config="{ altInput: true , altFormat: 'd/m/Y', dateFormat: 'Y-m-d' }"
	                                  	placeholder="Select a date"
	                                  	:disabled="isDisabled"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="End Date" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="End date"
	                              	rules="required"                                  
	                            >      
	                                <flat-pickr
	                                	v-model="detail.completion_date"
	                                  	class="form-control"           
	                                  	:config="{ altInput: true,altFormat: 'd/m/Y', dateFormat: 'Y-m-d'}"
	                                  	placeholder="Select a date"
	                                  	:disabled="isDisabled"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col> 	       
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Start Date Remarks" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Start Date Remarks"
	                              	rules="required"                                  
	                            >   
		                        	<b-form-textarea
		                        		v-model="detail.commencement_remark"
		                                placeholder="Brief Description"
		                                rows="3"
		                                :disabled="isDisabled"
		                                :formatter="length1000"
		                            ></b-form-textarea>
		                            <small class="text-danger">{{ errors[0] }}</small>
		                        </validation-provider>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="End Date Remarks" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="End Date Remarks"
	                              	rules="required"                                  
	                            >   
		                        	<b-form-textarea
		                        		v-model="detail.completion_remark"
		                                placeholder="Brief Description"
		                                rows="3"
		                                :disabled="isDisabled"
		                                :formatter="length1000"
		                            ></b-form-textarea>
		                            <small class="text-danger">{{ errors[0] }}</small>
		                        </validation-provider>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="12" class="px-0">
	                  		<div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
	                  	</b-col>  			                          	
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Leaders Trained (Direct)" label-cols-lg="4" class="label-break input-group-label">	                        	 
                            	<b-form-input
                            		v-model="detail.number_leader_trained_direct"
                                    placeholder="0"
                                    disabled
                                ></b-form-input>
	                        </b-form-group>
	                  	</b-col> 	     
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Specialists Trained (Direct)" label-cols-lg="4" class="input-group-label">
                            	<b-form-input
                            		v-model="detail.number_trainer_trained_direct"
                                    placeholder="0"
                                    disabled
                                ></b-form-input>
	                        </b-form-group>
	                  	</b-col> 				                          	
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Leaders Trained (Indirect)" label-cols-lg="4" class="label-break input-group-label">
                            	<b-form-input
                            		v-model="detail.number_leader_trained_indirect"
                                    placeholder="0"
                                    disabled
                                ></b-form-input>
	                        </b-form-group>
	                  	</b-col> 	     
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Specialists Trained (Indirect)" label-cols-lg="4" class="input-group-label">
                            	<b-form-input
                            		v-model="detail.number_trainer_trained_indirect"
                                    placeholder="0"
                                    disabled
                                ></b-form-input>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="12" class="px-0">
	                  		<div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
	                  	</b-col>   
	                  	<b-col lg="12" class="px-0">
	                        <b-form-group label="Project Remarks" label-cols-lg="2">
	                        	<b-form-textarea
	                        		v-model="detail.remarks"
	                                placeholder="Remarks"
	                                rows="3"
	                                :disabled="isDisabled"
	                                :formatter="length1000"
	                            ></b-form-textarea>
	                        </b-form-group>
	                  	</b-col>  
	              	</b-row>  
	            </div>

	        </b-form> 
	    </validation-observer>
	    <b-col class="export white-bg pt-2 px-0" style="border-radius: 5px">
	    	<b-row class="align-center">
	            <b-col lg="6" style="padding-left: 33px ">
	                <h2 class="mb-2">Project Experts</h2>
	            </b-col>
	            <b-col lg-6>	            	
	            	<div class="text-right mb-2 pr-2">
	            		<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
	                        Add Expert
	                    </b-button>
	                </div>
	            </b-col>
	        </b-row>	
	        <b-table small :fields="fields" :items="items" responsive="lg" tbody-tr-class="size-14 table-row" id="export-table" show-empty>
	        	<template #head(id)="data">
		    		<span>{{ data.label }}</span>
		    	</template>
			    <template #head(name)="data">
			        <span class="text-left">{{ data.label }}</span>
			    </template>
			    <template #head()="data">
			        <span class="d-block text-center">{{ data.label }}</span>
			    </template>
			    <template #cell(name)="data">
			        <span>{{ data.item.name }}</span>
			    </template>
			    <template #cell(id)="data">
					{{ data.item.id }}
				</template>	
				<template #cell(active)="data">
					<span class="d-block text-center" v-if="data.item.active == 0">Inactive</span>
					<span class="d-block text-center" v-else="data.item.active == 1">Active</span>
				</template>									
				<template #cell(action)="data">
					<span class="d-block text-center">
						<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#B1B1B1"/>
						</svg>
					</span>
				</template>
				<template #cell()="data">
					<span class="d-block text-center">{{ data.value }}</span>
				</template>
				<template #empty="scope">
			      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
			    </template>
	        </b-table>
	    </b-col>
	</section>
</template>

<script>

	const moment = require('moment')
	import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
	  	components: {
	  		flatPickr,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
	  	},
	  	data() {
		    return {
		    	isEditing: true,
            	isDisabled: false,
            	disableField: '',	
				projectCode: [],
			    codetam: [],
			    selectedCode: null,

			    selectCountry: [], 
			    selectedCountry: null,

			    selectOwner: [],
                selectedOwner: null,

			    selectStatus: [
		            {title: 'Active ', value: 0},  
		            {title: 'Completed', value: 1},          
		            {title: 'Developing', value: 2},
		            {title: 'Terminated', value: 3},
		        ],
		        selectedStatus: {title: 'Select status', value: null},  

		        selectClient: [],
		        selectedClient: null,
		        
		        selectPartner: [],
		        selectedPartner: null,

		        selectCurrency: [],

			    detail: [],

			    // experts
                fields: [
                	{key: 'id', label: 'ID'},
                	{ key: 'name', label: 'Fullname' },
			        { key: 'email', label: 'Email' },			        
			        { key: 'active', label: 'Status' },
			        { key: 'action', label: 'Actions' },
			    ],
		    	items: [],
			    tab_number: null, 

			    optionsType: [
			    	{ title: 'External', value: 0 },
			    	{ title: 'Ex-ITE', value: 1 },
			    	{ title: 'ITE', value: 2 },
			    ],
			    selectedType: null,
			    optionsProject: [],
			    selectedProjectExpert: null,
			    sortExpert: {"sort_field" : "id", "sort_type":"desc"}
            }
		},
		created(){
			this.detailItem()
			this.getProjectCode()
			this.listAllCountry()
			this.listOwner()
			this.listClient()
			this.listPartner()
			this.listCurrency()
			this.listExpert()
		},
		methods: {
			length1000(e){
	            return String(e).substring(0,1000)
	        },
	        length100(e){
	            return String(e).substring(0,100)
	        },
          	detailItem(){
          		this.$store
				.dispatch('project/detailProjectList', {
					id: this.$route.params.project_id	
				})
				.then(response => {

					this.detail = response.data.data
					document.title = response.data.data.name + ' - Project Listing - ITEES | Your Global Partner of Choice'	

					if(this.detail.country_id) this.getCountrybyId(this.detail.country_id)
					if(this.detail.user_id)	this.getOwnerbyId(this.detail.user_id)
					if(this.detail.client) this.getClientbyId(this.detail.client)
					if(this.detail.partner) this.getPartnerbyId(this.detail.partner)

					if(this.detail.status == 0) {
						this.selectedStatus.title = 'Active'
						this.selectedStatus.value = 0
					}
					if(this.detail.status == 1) {
						this.selectedStatus.title = 'Completed'
						this.selectedStatus.value = 1
					}
					if(this.detail.status == 2) {
						this.selectedStatus.title = 'Developing'
						this.selectedStatus.value = 2
					}
					if(this.detail.status == 3) {
						this.selectedStatus.title = 'Terminated'
						this.selectedStatus.value = 3
					}

					// console.log('this.detail: ', this.detail)
				})
				.catch((error) => {
					console.log(error)
				})
          	},
			getProjectCode(){
				this.$store
				.dispatch('auth/fetchProjectCodeList', {	
				})
				.then(response => {
					this.codetam = response.data.data
					this.projectCode = this.codetam.map(
		                (item) => {
		                	return{
		                		id: item.id,
		                		code: item.code
		                	}		                	
		                }
		              )
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getCountrybyId(id){
				this.$store
				.dispatch('auth/detailCountry', {
					id: id
				})
				.then(response => {
					this.selectedCountry = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listAllCountry(){
				this.$store
				.dispatch('auth/fetchCountry')
				.then(response => {
					this.selectCountry = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getOwnerbyId(id){
				this.$store
				.dispatch('auth/detailStaff', {
					id: id
				})
				.then(response => {
					this.selectedOwner = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listOwner(){
				this.$store
				.dispatch('auth/fetch_staff_log_list', {
		            filter: {
		                "group":"1",
		            }
		        })
				.then(response => {
					this.selectOwner = response.data.data.data
					// console.log('selectOwner: ', response.data.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getClientbyId(id){
				this.$store
				.dispatch('auth/detailOrganisation', {
					id: id
				})
				.then(response => {
					this.selectedClient = response.data.data
					// console.log('this.selectedClient: ', this.selectedClient)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listClient(){
				this.$store
				.dispatch('auth/Client_Organisation_List', {
		            filter: { 
		            	"list_search": [{ "field": "client","keyword": "1"}]
		        	}
		        })
				.then(response => {
					this.selectClient = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getPartnerbyId(id){
				this.$store
				.dispatch('auth/detailOrganisation', {
					id: id
				})
				.then(response => {
					this.selectedPartner = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listPartner(){
				this.$store
				.dispatch('auth/Client_Organisation_List', {
		            filter: { 
		            	"list_search": [{ "field": "partner","keyword": "1"}]
		        	}
		        })
				.then(response => {
					this.selectPartner = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listCurrency(){
				this.$store
				.dispatch('project/getCurrencyList')
				.then(response => {
					this.selectCurrency = response.data.data
					// console.log('listCurrency: ', response.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listExpert(){
				this.$store
				.dispatch('project/listExpertsAssign', {
					filter: { "project": this.$route.params.project_id },
					sort: this.sortExpert
				})
				.then(response => {
					// console.log('listExpert: ', response.data.data)
					this.items = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			cancelAction(){
				this.isEditing = true
				if(this.$route.params && this.$route.params.id) {
                	this.$router.push({ path: `/expert/our-portfolio/project/${this.$route.params.id}` })
                }
                else {
                	this.$router.push({ name: 'expert-our-portfolio-project' })
            	}
			},
			editAction(){
				this.isDisabled = false
	          	this.isEditing = false
	          	this.disableField= ''
			},
			saveAction(){
				this.$refs.form_rel.validate().then(success => {
					if(success){
						let list = {
		                    name: this.detail.name,
		                    description: this.detail.description,
		                    country_id: this.selectedCountry ? this.selectedCountry.id : null,
		                    province_state_city: this.detail.province_state_city,
		                    status: this.selectedStatus.value,
		                    amount: this.detail.amount, //project value
		                    user_id: this.selectedOwner ? this.selectedOwner.id : null,
		                    remarks: this.detail.remarks,
		                    commencement_date: this.detail.commencement_date,
		                    commencement_remark: this.detail.commencement_remark,
		                    completion_date: this.detail.completion_date,
		                    completion_remark: this.detail.completion_remark,
		                    currency_name: this.detail.currency_name,
		                    project_code: this.detail.project_code ? this.detail.project_code.id : null,
		                    client: this.selectedClient ? this.selectedClient.id : null,
		                    partner: this.selectedPartner ? this.selectedPartner.id : null,
		                }
		                if(this.selectedStatus.value == 0){
		                	this.tab_number = 1
		                }
		                else if(this.selectedStatus.value == 1){
		                	this.tab_number = 2
		                }
		                else if(this.selectedStatus.value == 2){
		                	this.tab_number = 3
		                }
		                else if(this.selectedStatus.value == 3){
		                	this.tab_number = 4
		                }

		                // console.log('data: ', list)
		                this.$store
		                .dispatch('project/editProjectList', 
		                {
		                    id: this.$route.params.project_id,
		                    data: list
		                })
		                .then(response => {
		                    this.$toast({
		                        component: ToastificationContent,
		                        props: {
		                          title: this.detail.name + ' Updated!',
		                          variant: 'success',
		                        },
		                      },
		                      {
		                        position: 'top-center'
		                      }
		                    )
	                    	if(this.$route.params && this.$route.params.id) {
		                    	this.$router.push({ path: '/expert/our-portfolio/project/' + this.selectedCountry.id, query: { tab: this.tab_number }})
		                    }
		                    else {
		                    	this.$router.push({ name: 'expert-our-portfolio-project', query: { tab: this.tab_number } })
		                	}
		                })
		                .catch((error) => {
		                    console.log(error)
		                    this.$toast({
		                        component: ToastificationContent,
		                        props: {
		                          title: error.response.data.message,
		                          variant: 'danger',
		                        },
		                      },
		                      {
		                        position: 'top-center'
		                      }
		                    )
		                })  
					}
					else{
		            	 this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
		            }
				})
			},
		}
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";

	#addExpertModal .modal-dialog{
		max-width: 600px;
	}
	.gift-table table thead tr th:first-child{
		min-width: 61px
	}
	.gift-table table thead tr th:last-child{
		min-width: 110px
	}
	#addExpertModal .modal-dialog .modal-header{
		border-bottom: 1px solid rgba(0, 0, 0, 0.7);
	}
	#addExpertModal .modal-dialog .row-container{
		padding-right: 80px;
    	padding-left: 50px;
	}
	#addExpertModal .modal-dialog .modal-body{
		padding-top: 18px;
    	padding-bottom: 0;
	}
	#addExpertModal .modal-dialog .modal-footer{
		padding-bottom: 18px;
		padding-top: 15px 
	}
	.tab-pt-0 .px-table-0 > .card-body {
	    padding-top: 0;
	}
</style>