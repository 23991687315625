<template>
	<section>
		<b-tabs card class="caption-add table-custom">
		 	<b-tab title="Project Level">
		 		<b-card class="px-table-0 mt-1 w-20-percent-cl-3 w-20-percent-cl-2">            		<div class="text-right mb-2 mr-2 pt-2">
				        <b-button type="button" variant="secondary" class="btn-df size-18 black btn-disabled-gray" disabled>
			                Add Document
			            </b-button>
	                </div>
				    <b-table small :fields="fields_documents" :items="documents" tbody-tr-class="size-14 table-row" show-empty @head-clicked="headClicked">
				    	<template #head(id)="data">
				    		<span>{{ data.label }}</span>
				    		<span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc'}]" style="position: relative; left: 20px;">
					        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
					        </span>
				    	</template>
				    	<template #head()="data">
				    		<span class="d-block text-center">{{ data.label }}</span>
				    	</template>
				    	<template #head(attach_file_title)="data">
				    		<span>{{ data.label }}</span>
				    	</template>	
				    	<template #cell(attach_file_title)="data">
				    		<span class="maxTwoRow">{{ data.item.attach_file_title }}</span>
				    	</template>	
				    	<template #cell(attach_content_type)="data">
							<span v-if="data.item.attach_content_type == 'null'"></span>
							<span v-else class="text-center maxThreeRow mx-auto">{{ data.item.attach_content_type }}</span>
						</template>		
				    	<template #cell(updated_at)="data">
							<span class="d-block text-center">{{ data.item.updated_at | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
						</template>	
				    	<template #cell(id)="data">					
							<span v-if="data.field.key == sort.field && sort.type == 'desc'">
								{{ total - data.index }}
							</span>
							<span v-else>
								{{ data.index + 1 }}
							</span>
						</template>	
						<template #cell(action)="data">
							<div class="d-block text-center">
								<span class="px-05 cursor-pointer" @click.stop="downloadDocument(data.item.attach_file_name, data.item.attach_file_title)">
									<feather-icon
							            icon="DownloadIcon"
							            size="22"
							          />
								</span>
								<span class="px-05 cursor-pointer">
									<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#b1b1b1"/>
									</svg>
								</span>
								<span class="px-05 cursor-pointer">
									<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#b1b1b1"/>
									</svg>
								</span>
							</div>										
						</template>		
						<template #cell()="data">
							<span class="d-block text-center">{{ data.value }}</span>
						</template>				
						<template #empty="scope">
					      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
					    </template>
					</b-table>
				</b-card>
				<div v-if="this.total > 0" class="paging-cus relative">
					<b-pagination
				      	v-model="currentPage"
				      	:total-rows="total"
				      	:per-page="perPage"
				      	aria-controls="active-table"
				      	first-number
			        	last-number
			        	class="just-center"
				    ></b-pagination>
				    <div class="limit-per">
						<select v-model="selected" class="cursor-pointer">
						    <option v-for="limit in limitpage">{{ limit }}</option>
						</select>			  
				    </div>
				</div>
		 	</b-tab>
		 	<b-tab title="Activity Level">
		 		<tabActivityLevel />
		 	</b-tab>
		</b-tabs>   
	</section>
</template>

<script>

	import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    import tabActivityLevel from './activityLevel/List'

	export default {
	  	components: {
	  		ValidationProvider,
          	ValidationObserver,
          	tabActivityLevel,
	  	},
	  	data() {
		    return {
			    fieldAdd: {		            
		            name: null,
		      		file: null,
		      		remarks: null
		        },
	            sort: {
			    	field: 'id',
			    	type: 'asc'
			    },
			    perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10',

    			fields_documents: [
                	{ key: 'id', label: 'No.' },
			        { key: 'attach_file_title', label: 'Document Name'},
			    	{ key: 'attach_content_type', label: 'Remarks'},
			    	{ key: 'updated_at', label: 'Uploaded Date'},
			    	{ key: 'action', label: 'Actions'},
			    ],
		    	documents: [],	
		    	fieldsDocumentShow: {
		      		id: null,
		      		name: null,
		      		file: null,
		      		remarks: ''
		      	},
            }
		},	
		mounted () {
		    this.selected = '10'
		},
		watch: {
			currentPage(val){
				this.featctList()
			},
			selected(val){
				if(val){
					this.perPage = val
					this.featctList()
				}
			},
		},
		created(){
			this.featctList()
		},
		methods: {
			featctList(){
				this.$store
				.dispatch('auth/fetchDocumentInfo', {
					filter: {
					  	"list_search": [
						    {
						      "attachable_id": this.$route.params.project_id,
						      "attachable_type": "project"
						    }
						]
					},
					limit: this.perPage,
					page: this.currentPage,
					sort: this.sort,
				})
				.then(response => {
					this.documents = response.data.data.data
					this.total = response.data.data.total
					// console.log('list: ', response.data.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
			},
	        length100(e){
	            return String(e).substring(0, 100)
	        },
	        length1000(e){
	            return String(e).substring(0, 1000)
	        },
			headClicked(key) {
				if(this.sort.field == key) {
					if(this.sort.type == 'asc') this.sort.type = 'desc'
					else this.sort.type = 'asc'
				} else {					
					this.sort.field = key
					this.sort.type = 'desc'
				}
				this.featctList()
			},
			downloadDocument(path, title){
		    	const url = `${process.env.VUE_APP_SERVER_URL}/api/downLoad?url_file=${path}`
		  		axios({
		        	method: 'get',
		        	url: url,
		        	responseType: 'blob',
		      	})
		      	.then(response => {		        
		        	this.forceFileDownload(response, title)	           
		      	})
		      	.catch(() => console.log('error occured'))
		    },
		    forceFileDownload(response, namFile){
		      	const url = window.URL.createObjectURL(new Blob([response.data]))
		      	const link = document.createElement('a')
		      	link.href = url
		      	link.setAttribute('download', namFile)
		      	document.body.appendChild(link)
		      	link.click()
		    },
		}
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";

	.tab-pt-0 .px-table-0 > .card-body {
	    padding-top: 0;
	}
</style>