<template>
	<section>	
		
		<b-row style="justify-content: space-between" class="mb-2 align-center">
		    <b-col>
				<Breadcrumb :labels="labels" :detailName="detailName" />				
		    </b-col>
		</b-row>
		<b-row>			
		    <b-col md="12">
		    	<div>
			        <b-tabs card class="caption-add table-custom" v-model="tabIndex">
			            <b-tab title="Basic Info" class="overview-tab">	
			            	<tabBasic />
			            </b-tab>
			            <b-tab title="Activity Listing">
			            	<tabActivity />
			            </b-tab>
			            <b-tab title="Documents" class="tab-child tab-pt-0">	
							<tabDocument />
			            </b-tab>    
			            <b-tab title="Users" class="tab-child tab-pt-0">
			            	<tabUsers />
			            </b-tab>                        
			        </b-tabs> 
			    </div>
			</b-col>
		</b-row>
	</section>
</template>

<script>
	import Breadcrumb from '@/views/itees/admin-dashboard/partial/Breadcrumb'
	import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    import tabBasic from './projectListing/basic/List'
    import tabActivity from './projectListing/activity/List'
    import tabDocument from './projectListing/document/List'
    import tabUsers from './projectListing/users/List'

	export default {
	  	components: {
	  		Breadcrumb,
	  		flatPickr,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,

          	tabBasic,
          	tabActivity,
          	tabDocument,
          	tabUsers,
	  	},
	  	data() {
		    return {
				labels: { "expert-our-portfolio-project-detail": "" },

			    isEditing: [],
			    projectListBasic_form: {},

			    tabIndex: 0,
			    detailName: { "expert-our-portfolio-project-detail-edit-blank": "" },
			    openButton: true,
            }
		},	
		watch: {
			"$route": function(val) {
				if(val){

					if(this.$route && this.$route.query && this.$route.query.tab) this.tabIndex = Number(this.$route.query.tab)	

					if(this.$route.params){
						if(this.$route.params.project_id &&  this.$route.name == 'expert-our-portfolio-project-detail-edit-blank' ) {
							this.getNameProject()
						}
					}
				}
			},
			tabIndex: function(val){
				if(val == 0) this.openButton = true
				else this.openButton = false
			}
		},	
		created(){
			
			if(this.$route && this.$route.query && this.$route.query.tab) this.tabIndex = Number(this.$route.query.tab)	

			if(this.$route.params.id) this.fetchCountryID()

			if(this.$route.params){
				if(this.$route.params.project_id &&  this.$route.name == 'expert-our-portfolio-project-detail-edit-blank' ) {
					this.getNameProject()
				}
			}					
		},
		methods: {		
			fetchCountryID(){
				this.$store
				.dispatch('country/fetchCountryID', this.$route.params.id)
				.then(response => {
					this.labels['expert-our-portfolio-project-detail'] = response.data.data.name
				})
				.catch((error) => {
					console.log(error)
				})
			},	
			getNameProject(){
				this.$store
				.dispatch('project/detailProjectList', {
					id: this.$route.params.project_id	
				})
				.then(response => {
					this.detailName['expert-our-portfolio-project-detail-edit-blank'] = response.data.data.name
				})
				.catch((error) => {
					console.log(error)
				})
			}
		}
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";

	#modal1 .modal-dialog, #modal2 .modal-dialog{
		max-width: 650px;
	}
	.gift-table table thead tr th:first-child{
		min-width: 61px
	}
	.gift-table table thead tr th:last-child{
		min-width: 110px
	}
	#modal1 .modal-dialog .modal-header, #modal2 .modal-dialog .modal-header{
		border-bottom: 1px solid rgba(0, 0, 0, 0.7);
	}
	#modal1 .modal-dialog .row-container, #modal2 .modal-dialog .row-container{
		padding-right: 80px;
    	padding-left: 50px;
	}
	#modal1 .modal-dialog .modal-body, #modal2 .modal-dialog .modal-body{
		padding-top: 30px;
    	padding-bottom: 0;
	}
	#modal1 .modal-dialog .modal-footer, #modal2 .modal-dialog .modal-footer{
		padding-bottom: 30px; 
	}
	.tab-pt-0 .px-table-0 > .card-body {
	    padding-top: 0;
	}
</style>