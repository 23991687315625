<template>
	<div>
		<b-card class="px-table-0 nonshadow w-15-percent-cl-1 w-15-percent-cl-2">
			<div class="text-right mb-2 mr-2">
				<b-button type="button" variant="secondary" class="btn-df size-18 black btn-disabled-gray" disabled>
	                Add Activity
	            </b-button>
	        </div>
		    <b-table small :fields="fields_activity" :items="activity" tbody-tr-class="size-14 table-row cursor-pointer" show-empty @head-clicked="headClicked" @row-clicked="detail">
		    	<template #head()="data">
		    		<span class="d-block text-center">{{ data.label }}</span>
		    	</template>		
		    	<template #head(name)="data">
		    		<span>{{ data.label }}</span>
		    	</template>	
		    	<template #head(activity_id)="data">
		    		<span>{{ data.label }}</span>
		    		<span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc'}]" style="position: relative; left:45px;">
			        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
			        </span>
			    </template>	
		    	<template #cell(activity_id)="data">					
					<span v-if="data.field.key == sort.field && sort.type == 'desc'">
						<template v-if="selected == 10">
							{{ ((total - data.index) - ((currentPage - 1) * 10 )) }}
						</template>
						<template v-else-if="selected == 20">
							{{ ((total - data.index) - ((currentPage - 1) * 20 )) }}
						</template>
						<template v-else>
							{{ ((total - data.index) - ((currentPage - 1) * 30 )) }}
						</template>
					</span>
					<span v-else>
						<template v-if="selected == 10">
							{{ ((currentPage - 1) * 10) + (data.index + 1) }}
						</template>
						<template v-else-if="selected == 20">
							{{ ((currentPage - 1) * 20) + (data.index + 1) }}
						</template>
						<template v-else>
							{{ ((currentPage - 1) * 30) + (data.index + 1) }}
						</template>
					</span>
				</template>	
				<template #cell(name)="data">
					<span>{{ data.item.name }}</span>
				</template>	
				<template #cell(type_service)="data">
					<span class="text-center d-block">{{ data.item.type_service.name }}</span>
				</template>	
				<template #cell(status)="data">
					<span class="d-block text-center">
						<span v-if="data.item.status == 0">Not Done</span>
						<span v-else-if="data.item.status == 1">In Progress</span>
						<span v-else-if="data.item.status == 2">Done</span>
						<span v-else>Terminated</span>
					</span>
				</template>	
				<template #cell(commencement_date)="data">
					<span class="d-block text-center">{{ data.item.commencement_date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
				</template>
				<template #cell(completion_date)="data">
					<span class="d-block text-center">{{ data.item.completion_date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
				</template>
				<template #cell(action)="data">
					<div class="d-block text-center">
						<span class="px-05">
							<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#B1B1B1"/>
							</svg>
						</span>
						<span class="px-05">
							<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#B1B1B1"/>
							</svg>
						</span>
					</div>										
				</template>		
				<template #cell()="data">
					<span class="d-block text-center">{{ data.value }}</span>
				</template>				
				<template #empty="scope">
			      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
			    </template>
			</b-table>
		</b-card>
		<div v-if="this.total > 0" class="paging-cus relative">
			<b-pagination
		      	v-model="currentPage"
		      	:total-rows="total"
		      	:per-page="perPage"
		      	aria-controls="overview-table"
		      	first-number
	        	last-number
	        	class="just-center"
		    ></b-pagination>
		    <div class="limit-per">
				<select v-model="selected" class="cursor-pointer">
				    <option v-for="limit in limitpage">{{ limit }}</option>
				</select>		
		    </div>
		</div>
	</div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	export default {
	  	components: {

	  	},
	  	data() {
		    return {
		    	fields_activity: [
                	{ key: 'activity_id', label: 'No', isSort: true },
			        { key: 'name', label: 'Activity Name', isSort: false },				        
			        { key: 'type_service', label: 'Type of Services', isSort: false },
			        { key: 'status', label: 'Activity Status', isSort: false },
			        { key: 'commencement_date', label: 'Start Date', isSort: false },
			        { key: 'completion_date', label: 'End Date', isSort: false },
			        { key: 'action', label: 'Actions', isSort: false },
			    ],
		    	activity: [],	

		    	sort: {
			    	field: 'activity_id',
			    	type: 'asc'
			    },
			    perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10',
            }
		},	
		mounted () {
		    this.selected = '10'
		},
		watch: {
			currentPage(val) {
				this.listActivity()
			},
			selected(val){
				if(val){
					this.perPage = val
					this.listActivity()
				}
			},
		},
		created(){
			this.listActivity()
		},
		methods: {
			listActivity(){
				this.$store
	            .dispatch('project/listProjectActivity', {
	            	limit: this.perPage,
					page: this.currentPage,
					sort: this.sort,
	              	filter: {
					  	"list_search": [
						    {
						      "field": "project",
						      "keyword": this.$route.params.project_id
						    }
					  	]
					}
	            })
	            .then(response => {
	            	this.activity = response.data.data.data
	            	this.total = response.data.data.total
	              	// console.log('list activity: ', response.data.data.data)
	            })
	            .catch((error) => {
	              console.log(error)
	            })
			},		
			showDeleteAction(id, title) {
		        this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
		          title: 'Delete',
		          size: 'sm',
		          buttonSize: 'sm',
		          okVariant: 'danger',
		          okTitle: 'Delete',
		          cancelTitle: 'Cancel',
		          footerClass: 'p-2 modal-delete',
		          hideHeaderClose: false,
		          centered: true
		        })
		          .then(value => {
		            	if(value){
		            		this.deleteItem(id, title)	
		            	}
		          })
		          .catch(err => {
		            // An error occurred
		          })
		    },
		    deleteItem(id, title){
		    	this.$store
				.dispatch('project/deleteActivityItem', {
					id: id	
				})
				.then(response => {
					this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                          title: title + ' Has been deleted!',
	                          variant: 'danger',
	                      	},
	                	},
	                	{
	                      	position: 'top-center'
	                	}
	                )
	                this.listActivity()	                			
				})
				.catch((error) => {
					console.log(error)
				})
		    },
		    addNewActivity(){
		    	this.$router.push(`/expert/our-portfolio/project/${this.$route.params.id}/edit/${this.$route.params.project_id}/add-activity`)
		    },
		    editItem(id){
				this.$router.push(`/expert/our-portfolio/project/${this.$route.params.id}/edit/${this.$route.params.project_id}/activity/${id}`)
			},
			headClicked(key, isSort) {
				if(isSort.isSort == true){
					if(this.sort.field == key) {
						if(this.sort.type == 'asc') this.sort.type = 'desc'
						else this.sort.type = 'asc'
					} else {
						this.sort.field = key
						this.sort.type = 'desc'
					}								
					this.listActivity()
				}
			},
			detail(item){
				this.$router.push(`/expert/our-portfolio/activity-listing/activity/${item.id}`)
			}
		}
	};	

</script>

<style lang="scss">
	@import "@/assets/scss/_ite_tab_table.scss";

	.tab-pt-0 .px-table-0 > .card-body {
	    padding-top: 0;
	}
</style>